* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: #f4f4f4;
}

.tracker-container {
  padding: 20px;
  max-width: 600px;
  margin: auto;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.header {
  background-color: #f97e0d;
  color: white;
  padding: 10px 0;
  text-align: center;
  font-size: 24px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.input-form {
  text-align: center;
  padding: 20px;
}

.input-form input {
  padding: 10px;
  margin: 10px;
  width: 80%;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.input-form button {
  padding: 10px 20px;
  background-color: #f97e0d;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.input-form button:hover {
  background-color: #d96e0c;
}

.tracking-info {
  text-align: left;
  padding: 20px;
}

.tracking-info h2,
.tracking-info h3 {
  text-align: center;
}

.timeline {
  position: relative;
  margin: 20px 0;
  padding: 0;
  list-style-type: none;
}

.timeline::before {
  content: '';
  position: absolute;
  top: 0;
  left: 20px;
  height: 100%;
  width: 4px;
  background: #f97e0d;
}

.timeline-item {
  position: relative;
  padding-left: 50px;
  margin-bottom: 20px;
}

.timeline-item:last-child {
  margin-bottom: 0;
}

.timeline-item::before {
  content: '';
  position: absolute;
  top: 0;
  left: 10px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #fff;
  border: 4px solid #f97e0d;
}

.timeline-item.active::before {
  background:#f97e0d;
  border-color: #f97e0d;
}

.timeline-item.active .timeline-content {
  background: #fff8e1;
  border: 2px solid #f97e0d;
}

.timeline-item.active {
  font-weight: bold;
}

.timeline-content {
  background: #f9f9f9;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.timeline-content p {
  margin: 5px 0;
}

.timeline-content time {
  font-size: 0.85em;
  color: #888;
}

.error {
  color: red;
}

.button-container {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}

.modern-button {
  padding: 10px 20px;
  background-color: #f97e0d;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.modern-button:hover {
  background-color: #d96e0c;
  transform: translateY(-2px);
}

/* Responsive Design */
@media (max-width: 1200px) {
  .tracker-container {
    max-width: 800px;
  }
}

@media (max-width: 768px) {
  .tracker-container {
    max-width: 90%;
  }

  .input-form input {
    width: 90%;
  }
}

@media (max-width: 600px) {
  .tracker-container {
    max-width: 100%;
    padding: 10px;
  }

  .input-form input {
    width: 100%;
  }

  .input-form button {
    width: 100%;
    padding: 15px;
  }

  .modern-button {
    width: 100%;
    padding: 15px;
  }
}

@media (max-width: 480px) {
  .header {
    font-size: 20px;
  }

  .input-form input {
    padding: 15px;
  }

  .tracking-info {
    padding: 10px;
  }

  .tracking-info h2, .tracking-info h3 {
    font-size: 18px;
  }

  .modern-button {
    padding: 10px;
  }
}

@media (max-width: 375px) {
  .header {
    font-size: 18px;
  }

  .input-form input {
    padding: 10px;
  }

  .tracking-info {
    padding: 5px;
  }

  .tracking-info h2, .tracking-info h3 {
    font-size: 16px;
  }

  .modern-button {
    padding: 8px;
  }
}

.language-select {
  text-align: center;
  margin: 10px 0;
}

.language-select label {
  display: block;
  margin-bottom: 5px;
  font-size: 16px;
}

.dropdown {
  display: inline-block;
  position: relative;
}

.dropdown-button {
  background-color: white;
  color: black;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  transition: border-color 0.3s;
}

.dropdown-button:hover {
  border-color: #f97e0d;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: white;
  min-width: 160px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  transition: background-color 0.3s;
}

.dropdown-content a:hover {
  background-color: #f97e0d;
  color: white;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropdown-button {
  border-color: #f97e0d;
}
